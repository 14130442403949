<template>
  <svg
    width="24"
    height="20"
    viewBox="0 0 24 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill="currentColor"
      d="M8.4 10C11.0513 10 13.2 7.8509 13.2 5.20002C13.2 2.54915 11.0513 0.400024 8.4 0.400024C5.74875 0.400024 3.6 2.54915 3.6 5.20002C3.6 7.8509 5.74875 10 8.4 10ZM8.4 2.20002C10.0541 2.20002 11.4 3.5459 11.4 5.20002C11.4 6.85415 10.0541 8.20002 8.4 8.20002C6.74625 8.20002 5.4 6.85377 5.4 5.20002C5.4 3.5459 6.74625 2.20002 8.4 2.20002ZM10.3013 11.8H6.49875C2.91038 11.8 0 14.71 0 18.2988C0 19.0188 0.582 19.6 1.29975 19.6H15.501C16.2188 19.6 16.8 19.0188 16.8 18.2988C16.8 14.71 13.89 11.8 10.3013 11.8ZM1.82662 17.8C2.07675 15.4413 4.07625 13.6 6.49875 13.6H10.3013C12.7241 13.6 14.6925 15.4424 14.9738 17.8H1.82662ZM23.1 7.90002H21.3V6.10002C21.3 5.60502 20.8988 5.20002 20.4 5.20002C19.9013 5.20002 19.5 5.60315 19.5 6.10002V7.90002H17.7C17.205 7.90002 16.8 8.30502 16.8 8.80002C16.8 9.29502 17.2031 9.70002 17.7 9.70002H19.5V11.5C19.5 11.9988 19.905 12.4 20.4 12.4C20.895 12.4 21.3 11.9969 21.3 11.5V9.70002H23.1C23.5988 9.70002 24 9.29877 24 8.80002C24 8.30127 23.5988 7.90002 23.1 7.90002Z"
    />
  </svg>
</template>
